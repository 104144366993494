<template lang="pug">
  .form-row
    .form-row-label
      FormFieldLabel(:title="$t('company_system.basic_settings.options.options_form.sales_destination_shop.label')")
    .form-row-field.flex-row.shop-destination-header
      .button-bar.flex-row
        AppButton(
          name="select-all"
          title="company_system.basic_settings.options.options_form.sales_destination_shop.select_all"
          @click="selectAll"
        )
        AppButton(
          name="cancel-all"
          title="company_system.basic_settings.options.options_form.sales_destination_shop.cancel_all"
          @click="$emit('clear-all')"
        )
        .label
          span {{ $t('company_system.basic_settings.options.options_form.sales_destination_shop.shop_selected', { selected: selectedShopsCount, total: totalShopsCount }) }}
      AppSearch(
        :value="filters.searchValue"
        @update="setSearchValue"
      )
    .form-row-field
      AppOverlayLoader(:state="shopsLoading")
      .sales-destination-shops
        .sales-destination-shops-table
          table
            thead
              tr
                th.sale
                  span {{ $t("company_system.basic_settings.options.options_form.sales_destination_shop.sale") }}
                th.prefectures.sortable(@click="handleSorting('prefecture_name')")
                  span {{ $t("company_system.basic_settings.options.options_form.sales_destination_shop.prefectures") }}
                  FaIcon.icon(:icon="sortingIcon('prefecture_name')")
                th.inventory-group.sortable(@click="handleSorting('inventory_group_name')")
                  span {{ $t("company_system.basic_settings.options.options_form.sales_destination_shop.inventory_group") }}
                  FaIcon.icon(:icon="sortingIcon('inventory_group_name')")
                th.shop-name.sortable(@click="handleSorting('name')")
                  span {{ $t("company_system.basic_settings.options.options_form.sales_destination_shop.shop_name") }}
                  FaIcon.icon(:icon="sortingIcon('name')")
            tbody
              tr(v-for="shop in shops")
                td
                  AppCheckbox(
                    :value="isShopSelected(shop)"
                    @change="selectShop(shop)"
                  )
                td {{ shop.prefecture_name }}
                td {{ shop.inventory_group_name }}
                td {{ shop.name }}
        AppPagination(
          :current-page="pagination.current_page"
          :total="pagination.total_count"
          :per-page="pagination.per_page"
          @change-pagination-data="changePagination"
        )
</template>

<script>
  // misc
  import { map } from "lodash-es"
  import { appDebounce } from "@/helpers/common"
  import { switchSorting, sortingIcon } from "@/helpers/matching"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withSorting from "@/mixins/withSorting"

  // stores
  import shopsModule from "@/config/store/company_system/shops_settings/shops/basic_settings"

  const shopsMixin = withStoreModule(shopsModule, {
    resetState: true,
    name: "companiesShops",
    readers: {
      shops: "items",
      shopsLoading: "loading",
      filters: "filters",
      pagination: "pagination",
      sortingData: "sorting"
    },
    actions: {
      fetchShops: "FETCH_ITEMS"
    },
    mutations: {
      setFilters: "SET_FILTERS",
      setPagination: "SET_PAGINATION_DATA",
      setSorting: "SET_SORTING"
    }
  })

  export default {
    components: {
      AppButton: () => import("@/components/elements/AppButton"),
      AppSearch: () => import("@/components/elements/AppSearch"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [withSorting, shopsMixin],

    props: {
      selectedShopIds: {
        type: Array,
        default: () => new Array()
      }
    },

    created() {
      this.debouncedFetchShops = appDebounce(() => this.fetchShops())
    },

    mounted() {
      this.fetchShops()
    },

    computed: {
      totalShopsCount() {
        return this.shops.length
      },

      selectedShopsCount() {
        return this.selectedShopIds.length > this.totalShopsCount ? this.totalShopsCount : this.selectedShopIds.length
      }
    },

    methods: {
      setSearchValue(value) {
        this.setFilters({ ...this.filters, searchValue: value })
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.debouncedFetchShops()
      },

      selectShop({ id }) {
        this.$emit("select-shop", id)
      },

      selectAll() {
        this.$emit("select-all", map(this.shops, "id"))
      },

      isShopSelected({ id }) {
        return this.selectedShopIds.includes(id)
      },

      changeFilters(filters) {
        this.setFilters(filters)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.debouncedFetchShops()
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.debouncedFetchShops()
      },

      handleSorting(newField) {
        this.changeSorting(switchSorting({ ...this.sortingData, newField }))
      },

      sortingIcon(field) {
        return sortingIcon(this.sortingData, field)
      },

      changeSorting(sorting) {
        this.setSorting(sorting)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.debouncedFetchShops()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .flex-row
    display: flex
    justify-content: left

  .form-row
    display: flex
    flex-direction: column
    margin-top: 20px

    &-label
      font-style: normal
      font-weight: 200
      font-size: 13px
      line-height: 19px

    &-field
      margin-top: 5px
      font-weight: 400
      font-size: 16px

      &.shop-destination-header
        justify-content: space-between

        .button-bar
          .label
            padding: 7px
            font-size: 15px
            color: $default-gray-medium
          button
            border: 1px solid $default-gray-medium
            margin-right: 10px
            padding: 5px

  .sales-destination-shops-table
    +listing-container(100px)

    table
      +custom-table
      +matchings-table-header-sticky

      table-layout: fixed
      overflow-wrap: break-word

      thead
        th
          max-width: none !important
          margin: 0

          &.sale
            width: 10%

          &.prefectures
            width: 20%

          &.inventory-group
            width: 20%

          &.shop-name
            width: 50%
      tbody
        tr
          td
            .app-checkbox
              padding-left: 2px
</style>
